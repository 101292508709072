import React, { Component } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
// import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import axios from "axios";
import BcaIcon from "assets/img/havilla/bca.svg";

// import Datepicker from 'client-side/components/Datepicker/Datepicker';
import CloseIcon from "assets/img/havilla/close_icon.svg";
import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import Chamomile from "assets/img/havilla/own_blend_square.jpg";
import SnackbarComp from "client-side/components/Snackbar/SnackbarComp";

import Fade from "@material-ui/core/Fade";

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { baseUrl, requestConfigAdmin } from "../../../Environment.js";
import { Backdrop, CircularProgress } from "@material-ui/core";

// import { ClientSideContext } from '../../ClientSideContext';

import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/client-side/App.js";
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import CustomSelect from "../CustomSelect/CustomSelect";
import moment from "moment";
import Datepicker from "client-side/components/Datepicker/Datepicker.js";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = (theme) => ({
	...styles(theme),
	textField: {
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		[theme.breakpoints.up("md")]: {},
		width: "100%",
	},
	iconEdit: {
		marginTop: `-8px`,
	},
	boxOutline: {
		padding: "1.2rem",
		background: '#222222'
	},
	paper: {
		[theme.breakpoints.down("sm")]: {
			padding: "1rem",
			width: "100%",
		},
		position: "absolute",
		width: "70%",
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: "3rem 4rem",
	},
	closeIcon: {
		position: "absolute",
		right: 20,
		top: 20,
		cursor: "pointer",
	},
	selectedIconType: {
		border: "1px solid black",
		padding: "5px",
	},
	unselectedIconType: {
		padding: "6px",
	},
	addNewContainer: {
		[theme.breakpoints.down("md")]: {
			paddingTop: 0,
		},
		[theme.breakpoints.down("lg")]: {
			paddingTop: "5%",
		},
		[theme.breakpoints.up("lg")]: {
			paddingTop: "12%",
		},
		height: "100%",
	},
	modalStyle: {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		overflowY: "auto",
		// height:'100%',
		height: "90%",
		display: "block",
	},
	modalStyleVA: {
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		// height:'100%',
		display: "block",
	},
	productBackground: {
		background: "#f0f0f0",
		height: "100%",
		width: "100%",
		padding: "0 20px",
		position: "relative",
	},
	productImage: {
		height: 100,
	},
	productImageOrder: {
		width: "100%",
	},
	heartIcon: {
		position: "absolute",
		right: "20px",
		top: "20px",
	},
	onProcess: {
		fontSize: "0.8rem",
		borderRadius: "16px",
		padding: "2px 10px 0",
		textTransform: "uppercase",
		width: "auto",
		background: "#C8E2C4",
		color: "#4A7C0B",
	},
	onDelivery: {
		fontSize: "0.8rem",
		borderRadius: "16px",
		padding: "2px 10px 0",
		textTransform: "uppercase",
		width: "auto",
		background: "rgba(173, 226, 255, 0.3)",
		color: "#489ECE",
	},
	onWaiting: {
		fontSize: "0.8rem",
		borderRadius: "16px",
		padding: "2px 10px 0",
		textTransform: "uppercase",
		width: "auto",
		background: "#FFE4B5",
		color: "#9B7207",
	},
	onCanceled: {
		fontSize: "0.8rem",
		borderRadius: "16px",
		padding: "2px 10px 0",
		textTransform: "uppercase",
		width: "auto",
		background: "#de9dac",
		color: "white",
	},
	borderHr: {
		marginTop: 15,
		marginBottom: 15,
		border: "1px solid #AF986D",
	},
	filtersContainer: {
		marginBottom: "24px",
	},
});
const borderStandart = {
	// border: "1px solid #f0f0f0",
};

const availableMonths = [
	{ text: "January", value: 1 },
	{ text: "February", value: 2 },
	{ text: "March", value: 3 },
	{ text: "April", value: 4 },
	{ text: "May", value: 5 },
	{ text: "June", value: 6 },
	{ text: "July", value: 7 },
	{ text: "August", value: 8 },
	{ text: "September", value: 9 },
	{ text: "October", value: 10 },
	{ text: "November", value: 11 },
	{ text: "December", value: 12 },
];
export class OrderCard extends Component {
	// static contextType = ClientSideContext;
	constructor(props) {
		super(props);

		this.state = {
			openBackdrop: false,

			open: false,
			openStatusVA: false,
			statusVA: "",

			offset: 0,
			limit: 30,
			filter: "On Process",
			foundNext: 0,

			snackbarType: "success",
			snackbarMessage: "",
			counter: 5,

			orders: [],
			selectedModal: null,

			id: "",
			order_details: [],
			user: [],
			address: [],
			status: "",
			notes: "",

			card_notes: "",
			for_gift: "",
			full_name: "",
			street_address: "",
			phone_no: "",
			postal_code: "",
			province_id: "",
			city_id: "",

			shipping_service: "",
			shipping_cost: "",
			tracking_no: "",

			total_amount: 0,
			resendGift: true,
			repeatOrder: true,

			discount: 0,
			code: "",

			// filters
			availableYears: [],
			availableDays: [],
			customerUsers: [],
			fltYear: "",
			fltMonth: "",
			fltDay: "",
			fltDateRangeStart: null,
			fltDateRangeEnd: null,
			fltUserId: "",
		};

		// this.handlePasswordChange = this.handlePasswordChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	getOrders = async () => {
		this.setState({ openBackdrop: true });
		await axios
			.post(
				`${baseUrl}/orders_admin`,
				{
					limit: this.state.limit,
					offset: this.state.offset,
					filter: this.state.filter,
					fltYear: this.state.fltYear,
					fltMonth: this.state.fltMonth,
					fltDay: this.state.fltDay,
					fltDateRangeStart: this.state.fltDateRangeStart,
					fltDateRangeEnd: this.state.fltDateRangeEnd,
					fltUserId: this.state.fltUserId,
				},
				requestConfigAdmin
			)
			.then(
				(res) => {
					if (res.data.success) {
						if (res.data.data.length > 0) {
							this.setState(
								{
									orders: [
										...this.state.orders,
										...res.data.data,
									],
								},
								() => {
									this.setState({ openBackdrop: false });
								}
							);
						} else {
							this.setState({
								offset: this.state.offset - this.state.limit,
							});
							this.setState({ openBackdrop: false });
						}

						this.setState({
							foundNext: res.data.found_next_item,
						});
					} else {
						this.setState({ openBackdrop: false });
						console.log(res.data.message, res.data.data);
					}
				},
				(e) => {
					this.setState({ openBackdrop: false });
					console.log("Error : ", e);
				}
			);
	};

	getOrdersFromFilter = async () => {
		this.setState({ openBackdrop: true });
		await axios
			.post(
				`${baseUrl}/orders_admin`,
				{
					limit: this.state.limit,
					offset: this.state.offset,
					filter: this.state.filter,
					fltYear: this.state.fltYear,
					fltMonth: this.state.fltMonth,
					fltDay: this.state.fltDay,
					fltDateRangeStart: this.state.fltDateRangeStart,
					fltDateRangeEnd: this.state.fltDateRangeEnd,
					fltUserId: this.state.fltUserId,
				},
				requestConfigAdmin
			)
			.then(
				(res) => {
					if (res.data.success) {
						this.setState(
							{
								orders: res.data.data,
							},
							() => {
								this.setState({ openBackdrop: false });
							}
						);
						this.setState({ openBackdrop: false });

						this.setState({
							foundNext: res.data.found_next_item,
						});
					} else {
						this.setState({ openBackdrop: false });
						console.log(res.data.message, res.data.data);
					}
				},
				(e) => {
					this.setState({ openBackdrop: false });
					console.log("Error : ", e);
				}
			);
	};

	getAvailableYears = async () => {
		await axios
			.get(`${baseUrl}/orders/available-years`, requestConfigAdmin)
			.then(
				(res) => {
					if (res.data.success) {
						if (res.data.data.length > 0) {
							this.setState(
								{
									availableYears: res.data.data,
								},
								() => { }
							);
						}
					} else {
						console.log(res.data.message, res.data.data);
					}
				},
				(e) => {
					console.log("Error : ", e);
				}
			);
	};

	getCustomerUsers = async () => {
		await axios
			.get(`${baseUrl}/users/get_customer_users`, requestConfigAdmin)
			.then(
				(res) => {
					if (res.data.success) {
						if (res.data.data.length > 0) {
							this.setState(
								{
									customerUsers: res.data.data,
								},
								() => { }
							);
						}
					} else {
						console.log(res.data.message, res.data.data);
					}
				},
				(e) => {
					console.log("Error : ", e);
				}
			);
	};

	componentDidMount = async () => {
		this.getOrders();
		this.getAvailableYears();
		this.getCustomerUsers();
	};

	handleLoadMore = () => {
		this.setState(
			{
				offset: this.state.offset + this.state.limit,
			},
			() => {
				this.getOrders();
			}
		);
	};

	handleSelectFilter = (status) => {
		this.setState(
			{
				orders: [],
				filter: status,
				offset: 0,
			},
			() => {
				this.getOrders();
			}
		);
	};

	handleOpenModal = (i) => {
		this.setState(
			{
				open: true,
				selectedModal: i,
			},
			() => {
				// (this.state.selectedModal)
			}
		);

		const order = this.state.orders.find((x) => x.id === i);
		// const address = this.state.orders[i];
		this.setState({
			id: i,
			for_gift: order.for_gift,
			order_details: order.order_details,
			user: order.user,
			address: order.address,
			status: order.status,
			notes: order.notes,
			card_notes: order.card_notes,
			total_amount: order.total_amount < 0 ? 0 : order.total_amount,
			full_name: order.full_name,
			street_address: order.street_address,
			phone_no: order.phone_no,
			postal_code: order.postal_code,
			province_id: order.province_id,
			city_id: order.city_id,
			shipping_service: order.shipping_service,
			shipping_cost: order.shipping_cost,
			tracking_no: order.tracking_no,
			discount: order.discount !== null ? order.discount : 0,
			code: order.code,
		});

		if (order.status === "On Delivery") {
			this.setState({
				resendGift: false,
				repeatOrder: false,
			});
		}
	};

	handleClose = () => {
		this.setState({
			open: false,
			openStatusVA: false,
			openFilterModal: false,
			tracking_no: "",
			// id: '',
			// order_details: [],
			// user: [],
			// address: [],
			// status: '',
			// notes: '',
			// total: 0,
			// resendGift: true,
			// repeatOrder: true
		});
	};

	handleCheckInquiryVa = async (request_id, i) => {
		this.setState({ openBackdrop: true });

		const order = this.state.orders.find((x) => x.id === i);
		this.setState({
			id: i,
		});

		if (request_id) {
			await axios
				.get(
					`${baseUrl}/va/inquiry_va/RequestID/12728/${request_id}`,
					requestConfigAdmin
				)
				.then(
					(res) => {
						if (res.data.success) {
							this.setState({
								statusVA:
									res.data.data.virtualAccountData.paymentFlagReason.english,
							});
						} else {
							if (res.data.message === "BCA returns error") {
								this.setState({
									statusVA:
										res.data.message +
										" (code: " +
										res.data.data.responseCode +
										") : " +
										res.data.data.responseMessage,
								});
							} else if (res.data.message === "Request ID Not Found") {
								this.setState({
									statusVA:
										res.data.message
								});
							} else {
								this.setState({
									statusVA:
										"Time out to BCA API, Try again later.",
								});
							}
							console.log(res.data.message, res.data.data);
						}

						this.setState({ openBackdrop: false });
						this.setState({
							openStatusVA: true,
						});
					},
					(e) => {
						this.setState({ openBackdrop: false });
						console.log("Error : ", e);
					}
				);
		} else {
			this.setState({
				statusVA:
					"This transaction not yet paid by customer. (Request ID not set)",
			});

			this.setState({ openBackdrop: false });
			this.setState({
				openStatusVA: true,
			});
		}
	};

	handleUpdateStatus = async (status) => {
		await axios
			.post(
				`${baseUrl}/orders_update/${this.state.id}`,
				{
					status: status,
				},
				requestConfigAdmin
			)
			.then(
				(res) => {
					if (res.data.success) {
						// kalau status berubah hapus dr view karena pindah filter
						if (
							this.state.status !== res.data.data.status &&
							this.state.filter !== "All"
						) {
							this.setState(
								{
									orders: [
										...this.state.orders.filter(
											(order) =>
												order.id !== this.state.id
										),
									],
								},
								() => {
									this.handleClose();

									this.setState(
										{
											snackbarType: "success",
											snackbarMessage:
												"Success set status, Order moved to " +
												res.data.data.status,
										},
										() => {
											this.setState({
												snackbarType: "success",
												snackbarMessage: "",
											});
										}
									);
									// if (this.props.editMode === false) {
									//     this.props.handleSelectAddress(res.data.data)
									// }
									// this.setState({ openBackdrop: false });
								}
							);
						} else {
							this.setState(
								{
									// addresses: [...this.state.addresses, res.data.data]
									orders: this.state.orders.map((order) => {
										if (order.id === res.data.data.id) {
											order.tracking_no =
												res.data.data.tracking_no;
											order.status = res.data.data.status;
										}
										return order;
									}),
								},
								() => {
									this.handleClose();

									this.setState(
										{
											snackbarType: "success",
											snackbarMessage:
												"Success set status",
										},
										() => {
											this.setState({
												snackbarType: "success",
												snackbarMessage: "",
											});
										}
									);
									// if (this.props.editMode === false) {
									//     this.props.handleSelectAddress(res.data.data)
									// }
									// this.setState({ openBackdrop: false });
								}
							);
						}
					} else {
						this.setState({
							snackbarType: "failed",
							snackbarMessage: "Failed Submit Tracking No",
						});
						console.log(res.data.message, res.data.data);
					}
				},
				(e) => {
					this.setState({
						snackbarType: "failed",
						snackbarMessage: "Failed Submit Tracking No",
					});
					console.log("Error : ", e);
				}
			);
	};

	handleSubmitTrackingNo = async () => {
		// const { dispatchCartItems } = this.context;
		await axios
			.post(
				`${baseUrl}/orders_update/${this.state.id}`,
				{
					// status: this.state.status,
					tracking_no: this.state.tracking_no,
				},
				requestConfigAdmin
			)
			.then(
				(res) => {
					if (res.data.success) {
						// kalau status berubah hapus dr view karena pindah filter
						if (
							this.state.status !== res.data.data.status &&
							this.state.filter !== "All"
						) {
							this.setState(
								{
									orders: [
										...this.state.orders.filter(
											(order) =>
												order.id !== this.state.id
										),
									],
								},
								() => {
									this.handleClose();

									this.setState(
										{
											snackbarType: "success",
											snackbarMessage:
												"Success set tracking number, Order moved to " +
												res.data.data.status,
										},
										() => {
											this.setState({
												snackbarType: "success",
												snackbarMessage: "",
											});
										}
									);
									// if (this.props.editMode === false) {
									//     this.props.handleSelectAddress(res.data.data)
									// }
									// this.setState({ openBackdrop: false });
								}
							);
						} else {
							this.setState(
								{
									// addresses: [...this.state.addresses, res.data.data]
									orders: this.state.orders.map((order) => {
										if (order.id === res.data.data.id) {
											order.tracking_no =
												res.data.data.tracking_no;
											order.status = res.data.data.status;
										}
										return order;
									}),
								},
								() => {
									this.handleClose();

									this.setState(
										{
											snackbarType: "success",
											snackbarMessage:
												"Success set tracking number",
										},
										() => {
											this.setState({
												snackbarType: "success",
												snackbarMessage: "",
											});
										}
									);
									// if (this.props.editMode === false) {
									//     this.props.handleSelectAddress(res.data.data)
									// }
									// this.setState({ openBackdrop: false });
								}
							);
						}
					} else {
						this.setState({
							snackbarType: "failed",
							snackbarMessage: "Failed Submit Tracking No",
						});
						console.log(res.data.message, res.data.data);
					}
				},
				(e) => {
					this.setState({
						snackbarType: "failed",
						snackbarMessage: "Failed Submit Tracking No",
					});
					console.log("Error : ", e);
				}
			);
	};

	renderOrders = () => {
		const { classes } = this.props;

		return this.state.orders.map((item, i) => {
			// alert(window.location.pathname.split("/")[2]);
			return (
				<Grid
					item
					lg={12}
					sm={12}
					xs={12}
					style={{ marginTop: 15 }}
					key={item.id}
				>
					<div style={borderStandart}>
						<Grid container>
							<Grid item xs={2}>
								{item.order_details.length > 0 ? (
									item.order_details[0].is_own === 0 ? (
										<React.Fragment>
											{item.thumbnail_url !==
												undefined ? (
												<Grid
													container
													className={
														classes.productBackground
													}
													alignItems="center"
													justify="center"
												>
													<img
														src={item.thumbnail_url}
														className={
															classes.productImageOrder
														}
														alt="product img"
													></img>
												</Grid>
											) : (
												<Grid
													container
													className={
														classes.productBackground
													}
													alignItems="center"
													justify="center"
												>
													<img
														src={Suddhabumi}
														alt="product img"
														className={
															classes.productImageOrder
														}
													></img>
												</Grid>
											)}
										</React.Fragment>
									) : (
										<Grid
											container
											className={
												classes.productBackground
											}
											alignItems="center"
											justify="center"
										>
											<img
												src={Chamomile}
												alt="product img"
												className={
													classes.productImageOrder
												}
											></img>
										</Grid>
									)
								) : (
									""
								)}
								{/* {
                                    item.thumbnail_url !== undefined ?
                                        <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                            <img src={item.thumbnail_url} className={classes.productImageOrder} alt='product img'></img>
                                        </Grid>
                                        :
                                        <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                            <img src={Suddhabumi} alt='product img' className={classes.productImageOrder}></img>
                                        </Grid>
                                } */}
								{/* <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                    <img src={Suddhabumi} alt='product img' className={classes.productImageOrder}></img>
                                </Grid> */}
							</Grid>
							<Grid item xs={4}>
								<div className={classes.boxOutline}>
									<label>
										{new Intl.DateTimeFormat("en-GB", {
											month: "long",
											day: "2-digit",
											year: "numeric",
											hour: "numeric",
											minute: "numeric",
										}).format(new Date(item.created_at))}
									</label>
									<br />
									<h4>Order No : #{item.id}</h4>
									<p
										className={classes.titleMediumSize}
										style={{ marginTop: 15 }}
									>
										{item.order_details.length > 0
											? item.order_details[0].is_own === 0
												? item.order_details[0]
													.sub_product.product
													.name
												: item.order_details[0]
													.own_product.name
											: ""}
									</p>
									{item.order_details.length - 1 > 0 ? (
										<p
											className={classes.linkPointer}
											onClick={() =>
												this.handleOpenModal(item.id)
											}
										>
											<u>
												And{" "}
												{item.order_details.length - 1}{" "}
												other items
											</u>
										</p>
									) : (
										""
									)}
									<br />
									{item.for_gift === 0 ? (
										<p>Deliver To:</p>
									) : (
										<p>Gift for: </p>
									)}
									{item.for_gift === 0 ? (
										<p>
											{item.user.first_name}&nbsp;
											{item.user.last_name}
											<br />
											{item.street_address}
											<br />
											{item.phone_no}
											<br />
											Address Notes: {item.notes}
										</p>
									) : (
										<p>
											{item.full_name}
											<br />
											{item.street_address}
											<br />
											{item.phone_no}
											<br />
											<br />
											Card Type:{" "}
											{item.card_type == 1
												? "Happy Birthday"
												: item.card_type == 2
													? "Congratulations"
													: "Empty Card"}
											<br />
											Card Notes: {item.card_notes}
										</p>
									)}
									{/* <p>{item.street_address}</p> */}
									<br />
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className={classes.boxOutline}>
									<p
										style={{ marginBottom: 15 }}
										className={
											item.status.toString() ===
												"On Process"
												? classes.onProcess
												: item.status.toString() ===
													"On Delivery"
													? classes.onDelivery
													: item.status.toString() ===
														"Waiting For Payment"
														? classes.onWaiting
														: classes.onCanceled
										}
									>
										{item.status}
									</p>
									<p>
										Payment Method :{" "}
										{item.payment_method === "bca" ||
											item.payment_method === "BCA" ? (
											<img
												src={BcaIcon}
												alt="close icon"
											></img>
										) : (
											""
										)}
									</p>
									<p>BCA VA No : {item.va_number}</p>
									<label>
										Payment Time : {item.transaction_date}
									</label>
									<hr className={classes.borderHr}></hr>
									{item.shipping_service !== "gosend" ? (
										<p>
											Delivery Notes :{" "}
											{item.delivery_notes}
										</p>
									) : (
										<React.Fragment>
											<p>
												Go-Send Delivery Date :&nbsp;
												{new Intl.DateTimeFormat(
													"en-GB",
													{
														month: "long",
														day: "2-digit",
														year: "numeric",
													}
												).format(
													new Date(item.delivery_date)
												)}
											</p>
											<p>
												Go-Send Delivery Time :{" "}
												{item.delivery_time}
											</p>
										</React.Fragment>
									)}
									<p>
										Shipping Service :{" "}
										{item.shipping_service}
									</p>
									{item.tracking_no ? (
										<p>
											Tracking Number :{" "}
											<b>{item.tracking_no}</b>
										</p>
									) : (
										""
									)}
									<br />

									<Button
										variant="outlined"
										className={classes.buttonDark}
										onClick={() =>
											this.handleOpenModal(item.id)
										}
										style={{ width: "100%", marginTop: 20 }}
									>
										View Order Details
									</Button>
									{/* {
                                        item.status.toString() === "Waiting For Payment" || item.status.toString() === "Canceled" ?
                                            <Button variant="outlined" className={classes.buttonLight}
                                                onClick={() => this.handleCheckInquiryVa(item.request_id, item.id)} style={{ width: '100%', marginTop: 20 }}>
                                                Check Payment Status from BCA
                                            </Button>
                                            :
                                            ""
                                    } */}
									{Date.now() - new Date(item.created_at) >
										48 * 60 * 60 * 1000 ? (
										""
									) : (
										<Button
											variant="outlined"
											className={classes.buttonLight}
											onClick={() =>
												this.handleCheckInquiryVa(
													item.request_id,
													item.id
												)
											}
											style={{
												width: "100%",
												marginTop: 20,
											}}
										>
											Check Payment Status from BCA
										</Button>
									)}
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			);
		});
	};

	renderOrderDetails = () => {
		const { classes } = this.props;
		return this.state.order_details.map((item, i) => {
			return (
				<Grid container spacing={3} key={item.id}>
					<Grid item xs={3}>
						{item.is_own === 0 ? (
							<a
								href={`/product/${item.sub_product.product.url_slug}`}
								target="_blank"
							>
								{item.thumbnail_url !== undefined ? (
									<Grid
										container
										className={classes.productBackground}
										alignItems="center"
										justify="center"
									>
										<img
											src={item.thumbnail_url}
											className={classes.productImage}
											alt="product img"
										></img>
									</Grid>
								) : (
									<Grid
										container
										className={classes.productBackground}
										alignItems="center"
										justify="center"
									>
										<img
											src={Suddhabumi}
											alt="product img"
											className={classes.productImage}
										></img>
									</Grid>
								)}
							</a>
						) : (
							<Grid
								container
								className={classes.productBackground}
								alignItems="center"
								justify="center"
							>
								<img
									src={Chamomile}
									alt="product img"
									className={classes.productImage}
								></img>
							</Grid>
						)}
						{/* <Grid container className={classes.productBackground} alignItems="center" justify="center">
                            <img src={Suddhabumi} alt='product img' className={classes.productImage}></img>
                        </Grid> */}
					</Grid>
					<Grid item xs={6} style={{ paddingTop: 35 }}>
						<p>
							<b>({item.qty}X)</b> -&nbsp;
							{item.is_own === 0
								? item.sub_product.product.name
								: item.own_product.name +
								" by " +
								item.own_product.maker_name +
								" (Own Blend)"}
							<br />
						</p>
						{item.is_own === 0 ? (
							item.sub_product.product.sub_products.length > 1 ? (
								<p>varian : {item.sub_product.varian}</p>
							) : (
								""
							)
						) : (
							""
						)}
						{item.is_own === 0 ? (
							<p>@Rp. {item.price.toLocaleString("ID")}</p>
						) : (
							<p>
								@Rp.{" "}
								{item.own_product.price.toLocaleString("ID")}
							</p>
						)}
						{item.is_own === 0 ? (
							""
						) : (
							<React.Fragment>
								Ingredients: &nbsp;
								{item.own_product.own_product_details.map(
									(detail, i) => {
										return (
											"[" +
											detail.own_product_ingredient
												.name_en +
											"] "
										);
									}
								)}
							</React.Fragment>
						)}
					</Grid>
					<Grid item xs={3} style={{ paddingTop: 60 }}>
						{item.is_own === 0 ? (
							<p style={{ float: "right" }}>
								Rp.{" "}
								{(item.price * item.qty).toLocaleString("ID")}
							</p>
						) : (
							<p style={{ float: "right" }}>
								Rp.{" "}
								{(
									item.own_product.price * item.qty
								).toLocaleString("ID")}
							</p>
						)}
					</Grid>
				</Grid>
			);
		});
	};

	renderModal = () => {
		const { classes } = this.props;
		// Check to see if there's a selected post. If so, render it.
		return (
			<div className={`${classes.modalStyle} ${classes.paper}`}>
				<Grid container spacing={4}>
					<Grid item sm={6} xs={12}>
						<img
							className={classes.closeIcon}
							src={CloseIcon}
							alt="close icon"
							onClick={this.handleClose}
						></img>
						<h2
							className={classes.titleMediumSize}
							style={{ marginBottom: 15 }}
						>
							Order No: #{this.state.id}
						</h2>

						<div>{this.renderOrderDetails()}</div>
						<hr className={classes.borderHr}></hr>
						<p>
							Shipping Cost
							<span style={{ float: "right" }}>
								Rp.{" "}
								{this.state.shipping_cost.toLocaleString("ID")}
							</span>
						</p>
						{this.state.discount > 0 ? (
							<p>
								Discount ({this.state.code})
								<span
									style={{ float: "right", color: "green" }}
								>
									- Rp.{" "}
									{this.state.discount.toLocaleString("ID")}
								</span>
							</p>
						) : (
							""
						)}
						<hr className={classes.borderHr}></hr>
						<p>
							<b>
								Grand Total
								<span style={{ float: "right" }}>
									Rp.{" "}
									{this.state.total_amount.toLocaleString(
										"ID"
									)}
								</span>
							</b>
						</p>
						<hr className={classes.borderHr}></hr>
					</Grid>

					<Grid item sm={6} xs={12}>
						<h2
							className={classes.titleMediumSize}
							style={{ marginBottom: 15 }}
						>
							Delivery Information
						</h2>
						<p>
							Name of Recipient
							{this.state.for_gift === 0 ? (
								<span style={{ float: "right" }}>
									{this.state.user.first_name}{" "}
									{this.state.user.last_name}
								</span>
							) : (
								<span style={{ float: "right" }}>
									{this.state.full_name}
								</span>
							)}
						</p>
						<hr className={classes.borderHr}></hr>
						<p>
							Recipient Phone Number
							<span style={{ float: "right" }}>
								{this.state.phone_no}
							</span>
						</p>
						<hr className={classes.borderHr}></hr>
						<Grid container spacing={1}>
							<Grid item xs={4}>
								Delivery Address
							</Grid>
							<Grid item xs={8}>
								<span
									style={{
										float: "right",
										textAlign: "right",
									}}
								>
									{this.state.street_address}
								</span>
							</Grid>
						</Grid>
						<hr className={classes.borderHr}></hr>
						<p>
							Delivery Using
							<span style={{ float: "right" }}>
								{this.state.shipping_service}
							</span>
						</p>
						<hr className={classes.borderHr}></hr>
						<p>
							Order Status
							<span
								style={{ float: "right" }}
								className={
									this.state.status.toString() ===
										"On Process"
										? classes.onProcess
										: this.state.status.toString() ===
											"On Delivery"
											? classes.onDelivery
											: this.state.status.toString() ===
												"Waiting For Payment"
												? classes.onWaiting
												: classes.onCanceled
								}
							>
								{this.state.status}
							</span>
						</p>
					</Grid>

					{this.state.status.toString() !== "Waiting For Payment" &&
						this.state.status.toString() !== "Canceled" ? (
						<Grid item sm={12} xs={12}>
							<h2 className={classes.titleMediumSize}>
								Tracking Number
							</h2>

							<TextField
								style={{ width: 300 }}
								value={this.state.tracking_no}
								name="tracking_no"
								type="text"
								label="Tracking Number"
								onChange={this.onChange}
							/>

							<Button
								variant="outlined"
								className={classes.buttonDark}
								onClick={this.handleSubmitTrackingNo}
								style={{
									width: 200,
									marginLeft: 15,
									marginTop: 15,
									height: 35,
								}}
							>
								Set Tracking Number
							</Button>
						</Grid>
					) : (
						""
					)}
				</Grid>
			</div>
		);
	};

	renderModalStatusVA = () => {
		const { classes } = this.props;
		// Check to see if there's a selected post. If so, render it.
		return (
			<div className={`${classes.modalStyleVA} ${classes.paper}`}>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<img
							className={classes.closeIcon}
							src={CloseIcon}
							alt="close icon"
							onClick={this.handleClose}
						></img>
						<h2 className={classes.titleMediumSize}>
							Payment Status from BCA
						</h2>
						<p>{this.state.statusVA}</p>
						{this.state.statusVA === "SUCCESS" ? (
							<div>
								<Button
									variant="outlined"
									className={classes.buttonDark}
									onClick={this.handleUpdateStatus.bind(
										this,
										"On Process"
									)}
									style={{ marginTop: 20 }}
								>
									Set Manually Order To "On Process"
								</Button>
								<Button
									variant="outlined"
									className={classes.buttonLight}
									onClick={this.handleUpdateStatus.bind(
										this,
										"Canceled"
									)}
									style={{ marginTop: 20 }}
								>
									Set Manually Order To "Canceled"
								</Button>
							</div>
						) : (
							""
						)}
					</Grid>
				</Grid>
			</div>
		);
	};

	handleOpenFilterModal = () => {
		this.setState({
			openFilterModal: true,
		});
	};

	onChangeFltDate = (e, name) => {
		this.setState(
			{
				[name]: e.target.value,
				fltDateRangeStart: null,
				fltDateRangeEnd: null,
			},
			() => {
				this.getOrdersFromFilter();
				if (!!this.state.fltYear && !!this.state.fltMonth) {
					const lastDayDate = new Date(
						this.state.fltYear,
						this.state.fltMonth,
						0
					);
					const lastDate = moment(lastDayDate).format("D");

					let availableDays = [];
					for (let i = 1; i <= lastDate; i++) {
						availableDays.push(i);
					}
					this.setState({
						availableDays,
					});
				}
			}
		);
	};

	onChangeFltRange = (newVal, name) => {
		let isValid = true;
		if (name === "fltDateRangeStart") {
			if (!!this.state.fltDateRangeEnd) {
				if (moment(newVal) > moment(this.state.fltDateRangeEnd)) {
					isValid = false;
					this.setState({
						snackbarType: "error",
						snackbarMessage:
							'"To" Date cannot be before "From" date',
					});
				}
			}
		} else {
			if (!!this.state.fltDateRangeStart) {
				if (moment(this.state.fltDateRangeStart) > moment(newVal)) {
					isValid = false;
					this.setState({
						snackbarType: "error",
						snackbarMessage:
							'"To" Date cannot be before "From" date',
					});
				}
			}
		}

		if (isValid)
			this.setState(
				{
					[name]: newVal,
					fltYear: "",
					fltMonth: "",
					fltDay: "",
				},
				() => {
					if (
						!!this.state.fltDateRangeStart &&
						!!this.state.fltDateRangeEnd
					) {
						this.getOrdersFromFilter();
					}
				}
			);
	};

	onChangeFltUserId = (e, newVal) => {
		if (!!newVal)
			this.setState(
				{
					fltUserId: newVal,
				},
				() => {
					this.getOrdersFromFilter();
				}
			);
	};

	resetFilter = () => {
		this.setState(
			{
				fltUserId: '',
				fltYear: "",
				fltMonth: "",
				fltDay: "",
				fltDateRangeStart: null,
				fltDateRangeEnd: null,
			},
			() => {
				this.getOrdersFromFilter();
			}
		);
	};

	renderFilterModal = () => {
		const { classes } = this.props;
		// Check to see if there's a selected post. If so, render it.
		return (
			<div
				className={`${classes.modalStyleFilter} ${classes.paper}`}
			></div>
		);
	};

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<SnackbarComp
					snackbarType={this.state.snackbarType}
					snackbarMessage={this.state.snackbarMessage}
					counter={this.state.counter}
				/>
				<Backdrop
					className={classes.backdrop}
					open={this.state.openBackdrop}
				>
					<CircularProgress style={{ 'color': '#AF986D' }} />
				</Backdrop>

				<Grid container className={classes.filtersContainer}>
					<Grid item xs={12}>
						<ExpansionPanel>
							<ExpansionPanelSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="filter-panel-content"
								id="filter-panel-header"
							>
								<Typography>Filters</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<Grid container spacing={3}>
									<Grid item xs={4}>
										<CustomSelect
											fullWidth
											id="fltYear"
											label="Year"
											name="fltYear"
											options={this.state.availableYears.map(
												(year) => ({
													value: year,
													text: year,
												})
											)}
											value={this.state.fltYear}
											onChange={(e) =>
												this.onChangeFltDate(
													e,
													"fltYear"
												)
											}
										/>
									</Grid>
									<Grid item xs={4}>
										<CustomSelect
											fullWidth
											id="fltMonth"
											label="Month"
											name="fltMonth"
											options={availableMonths}
											value={this.state.fltMonth}
											onChange={(e) =>
												this.onChangeFltDate(
													e,
													"fltMonth"
												)
											}
										/>
									</Grid>
									<Grid item xs={4}>
										<CustomSelect
											disabled={
												this.state.availableDays
													.length === 0
											}
											fullWidth
											id="fltDay"
											label="Day"
											name="fltDay"
											options={this.state.availableDays.map(
												(day) => ({
													value: day,
													text: day,
												})
											)}
											value={this.state.fltDay}
											onChange={(e) =>
												this.onChangeFltDate(
													e,
													"fltDay"
												)
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<Divider />
									</Grid>
									<Grid item xs={6}>
										<Datepicker
											id="fltDateRangeStart"
											width="100%"
											label="From"
											value={this.state.fltDateRangeStart}
											name="fltDateRangeStart"
											handleDateChange={(newVal) =>
												this.onChangeFltRange(
													newVal,
													"fltDateRangeStart"
												)
											}
										/>
									</Grid>
									<Grid item xs={6}>
										<Datepicker
											id="fltDateRangeEnd"
											width="100%"
											label="To"
											value={this.state.fltDateRangeEnd}
											name="fltDateRangeEnd"
											handleDateChange={(newVal) =>
												this.onChangeFltRange(
													newVal,
													"fltDateRangeEnd"
												)
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<Divider />
									</Grid>
									<Grid item xs={12}>
										<CustomSelect
											disabled={
												this.state.customerUsers
													.length === 0
											}
											fullWidth
											id="fltUserId"
											label="User"
											name="fltUserId"
											options={this.state.customerUsers.map(
												(user) => ({
													value: user.id,
													text: `${user.first_name} ${user.last_name} | ${user.email}`,
												})
											)}
											value={this.state.fltUserId}
											onChange={this.onChangeFltUserId}
											hasSearch
										/>
									</Grid>
									<Grid
										item
										xs={12}
										container
										justify="flex-end"
									>
										<ButtonAdmin
											color="info"
											onClick={this.resetFilter}
										>
											Reset Filter
										</ButtonAdmin>
									</Grid>
								</Grid>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Grid>
				</Grid>

				<Button
					style={{ width: "20%" }}
					variant="outlined"
					className={
						this.state.filter === "All"
							? classes.buttonDark
							: classes.buttonLight
					}
					onClick={this.handleSelectFilter.bind(this, "All")}
				>
					All
				</Button>
				<Button
					style={{ width: "20%" }}
					variant="outlined"
					className={
						this.state.filter === "On Process"
							? classes.buttonDark
							: classes.buttonLight
					}
					onClick={this.handleSelectFilter.bind(this, "On Process")}
				>
					On Process
				</Button>
				<Button
					style={{ width: "20%" }}
					variant="outlined"
					className={
						this.state.filter === "On Delivery"
							? classes.buttonDark
							: classes.buttonLight
					}
					onClick={this.handleSelectFilter.bind(this, "On Delivery")}
				>
					On Delivery
				</Button>
				<Button
					style={{ width: "20%" }}
					variant="outlined"
					className={
						this.state.filter === "Waiting For Payment"
							? classes.buttonDark
							: classes.buttonLight
					}
					onClick={this.handleSelectFilter.bind(
						this,
						"Waiting For Payment"
					)}
				>
					Waiting For Payment
				</Button>
				<Button
					style={{ width: "20%" }}
					variant="outlined"
					className={
						this.state.filter === "Canceled"
							? classes.buttonDark
							: classes.buttonLight
					}
					onClick={this.handleSelectFilter.bind(this, "Canceled")}
				>
					Canceled
				</Button>

				{this.renderOrders()}
				{this.state.orders.length === 0 ? (
					<Grid item xs={12}>
						<p style={{ textAlign: "center", marginTop: 30 }}>
							You have no order in this category/filters.
						</p>
					</Grid>
				) : (
					""
				)}
				{this.state.foundNext === 1 ? (
					<Button
						variant="outlined"
						className={classes.buttonLight}
						onClick={this.handleLoadMore}
						style={{ width: "100%", marginTop: 15, height: 50 }}
					>
						Load More
					</Button>
				) : (
					""
				)}
				<Modal
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					closeAfterTransition
				>
					<Fade in={this.state.open}>
						{/* {body} */}
						<div>{this.renderModal()}</div>
					</Fade>
				</Modal>

				<Modal
					open={this.state.openStatusVA}
					onClose={this.handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					closeAfterTransition
				>
					<Fade in={this.state.openStatusVA}>
						{/* {body} */}
						<div>{this.renderModalStatusVA()}</div>
					</Fade>
				</Modal>
			</React.Fragment>
		);
	}
}

export default withStyles(useStyles)(OrderCard);
